.number-keyboard {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: min(210px, 90%);
  // justify-content: space-between;
  // margin: 20px 0;

  .item {
    width: 33%;
    margin-bottom: 10px;
  }
}
