@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

#root {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.main_section {
  /* height: 490px; */
  height: 100%;
  /* width: 700px; */
  width: 100%;
}

.Notes_main_1 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
}

/* .Notes_main_1 header{
  display: none !important;
} */

.Notes_main_1 header {
  height: 25px;
  font-size: 14px;
  color: #525252;
  background-color: #e9ebeb;
  border-radius: 10px 10px 0px 0px;
  border-bottom: 2px solid #cecece;
  position: relative;
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.Notes_main_2 {
  font-family: "Inter", sans-serif;
  height: 465px;
  font-weight: 700;
  background-color: #efefef;
}

.Notes_sub {
  display: flex;
  justify-content: center;
}

.Notes_section {
  background-color: #f7f7f7;
  padding: 0 15px;
}

.Notes_main {
  font-family: "Inter", sans-serif;
  /* height: 465px; */
  height: 100%;
  font-weight: 700;
  /* height: 80%;
    width: 85%; */
  /* background-color: #DEE2E6; */
}

.Notes_main header {
  height: 6%;
  font-size: 21px;
  color: #525252;
  background-color: #e9ebeb;
  border-radius: 10px 10px 0px 0px;
  border-bottom: 2px solid #cecece;
  position: relative;
}

.close-btn {
  position: absolute;
  left: 0;
  color: #da6b60;
  line-height: 0px;
  border-radius: 50%;
  border: none;
}

.Notes_type_section {
  height: 40px;
  /* border-bottom: 1px solid #CECECE; */
  /* background-color: #DEE2E6; */
  background-color: #f7f7f7;
  --bs-gutter-x: 0 !important;
  font-size: 12px;
  color: #525252;
  font-weight: 600;
  margin-top: 10px;
}

.Notes_type_section .note_type {
  cursor: pointer;
}

.note_type_back_btn {
  font-weight: 500;
}

.Notes_type_section .note_type.active {
  color: #cf33ff;
  border-bottom: 2px solid #cf33ff;
}

.Main_Notes_type {
  font-size: 12px; 
  height: 35px;
}

.Main_Notes_type .note_type{
  background-color: #FFFFFF;
  color: #A3A3A3;
  border-radius: 12px;
  border: 1px solid #ECEFF4;
}

.Main_Notes_type .note_type.active{
  color: #cf33ff;
  border: 1px solid #cf33ff;
}

.note-type-opend {
  text-align: right;
  /* background-color: #DEE2E6; */
  background-color: #f7f7f7;
}

.note_type_body {
  /* background-color: #DEE2E6; */
  background-color: #f7f7f7;
  /* padding: 0px 20px; */
  height: -webkit-fill-available;
}

.vehicle_info_main {
  /* height: 430px; */
  /* height: 100%; */
  /* overflow-y: scroll; */
  /* overflow-x: hidden; */
}

/* .vehicle_info_main::-webkit-scrollbar{
  width: 6px !important;
  background-color: #F5F5F5;
  margin-left: 5px;
  border-radius: 10px;
}

.vehicle_info_main::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.vehicle_info_main::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #DEE2E6;
} */

.Related_Vehicles_body {
  /* background-color: #DEE2E6; */
  background-color: #f7f7f7;
  padding: 0px 0px 40px 0px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.Related_Vehicles_body::-webkit-scrollbar {
  width: 6px !important;
  background-color: #f5f5f5;
  margin-left: 5px;
  border-radius: 10px;
}

.Related_Vehicles_body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.Related_Vehicles_body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #dee2e6;
}

.note_input_section input {
  margin-right: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.input_description_sec .input_description {
  width: 100%;
  resize: none;
}

.input_description_sec .input_description::-webkit-scrollbar {
  width: 0;
}

.note_input_section .add_notes_btn {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.note_input_section .add_notes_btn:hover {
  background-color: #0056b3;
}

.contact_icon {
  margin-top: 24px;
  display: flex;
  justify-content: center;
}

.NameNum {
  text-align: center;
}

.Notes_main_2 .NameNum h1 {
  font-size: 24px;
  color: #202020;
  font-weight: 600;
  margin: 8px 0px 10px;
}

.Notes_main_2 .NameNum p {
  font-size: 13px;
  font-weight: 500;
  margin: 0px;
  color: #525252;
}

.main-for-whole-section {
  height: 100%;
  background-color: transparent;
  /* border-bottom: 1px solid #CECECE; */
  --bs-gutter-x: 0 !important;
  position: relative;
}

.gridIcons {
  width: 75%;
  margin-top: 40px;
}

.gridItem {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Notes_main_2 .gridItem p {
  color: #323232;
  font-size: 10px;
  font-weight: 500;
  margin: 7px 0px;
}

.Notes_main_2 .gridIcons img {
  height: 50px;
  width: 50px;
}

.red_icon {
  margin-top: 15px;
}

.red_icon img {
  height: 75px;
  width: 75px;
}

.carSection {
  height: 695px;
  background: #dee2e6;
  background-size: cover;
  font-family: Arial, sans-serif;
  display: flex;
  align-items: center;
  margin: 0;
  flex-direction: column;
}

/* body {
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
} */

.search-container {
  position: relative;
  width: 90%;
  margin-top: 20px;
}

.search-input {
  width: 100%;
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-size: 16px;
  background-color: transparent;
  padding: 4px 20px;
}

.search-button {
  position: absolute;
  left: 10px;
  top: 45%;
  width: 16px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  /* cursor: pointer; */
}

.search-button img {
  width: 20px;
  height: 20px;
}

.carList p {
  color: #009dff;
  font-size: 29px;
  font-weight: bold;
}

.carList {
  width: 90%;
}

.carList img {
  height: 120px;
  width: 120px;
}

.vehicle-info {
  --bs-gutter-x: 0 !important;
  padding: 12px 0px;
  border-bottom: 1px solid #ebebeb;
}

/* .vehicle-data{
  border-bottom: 1px solid #CECECE;
  padding: 12px 0px ;
} */

.vehicle-data .vehicle-tag {
  font-size: 10px;
  color: #000000;
  font-weight: 500;
}

.vehicle-data .vehicle-tag a {
  color: #cf33ff;
  text-decoration: none;
}

.vehicle-tag .bi::before,
[class^="bi-"]::before,
[class*=" bi-"]::before {
  font-weight: 900 !important;
}

.vehicle-data .vehicle-tag span {
  color: #363636;
}

.vehicle-data .vehicle-name {
  font-size: 14px;
  font-weight: 700;
  color: #202020;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.vehicle-data .vehicle-short-discrip {
  font-size: 8px;
  font-weight: 500;
  color: #585858;
  line-height: 12px;
}

.vehicle-data .open-new-window {
  margin-top: 3px;
}

.vehicle-data .open-new-window button {
  font-size: 10px;
  font-weight: 500;
  color: #009dff;
  font-style: italic;
  border: none;
  background: transparent;
}

.vehicle-data .open-new-window button img {
  margin-top: -3px;
}

.vehicle-img .vehicle-img-inner {
  height: 80px;
  width: 100px;
  /* background: url(https://s3-alpha-sig.figma.com/img/f702/b617/a3ba83810374a206fcd41a4f5d68d7d3?Expires=1720396800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=IrEhtxxvvkONq4cXLp5cK2uOYa~J6ZFXUUhYca~C~EYvoXMX47Jh0E1QTny2ZmQAE0KcND1vf7H8h0FdPSXWhANiTXETvW9woy7ntROW586qLeNMHUNwKw8ZpoHBiSngJ1qbOAxeCidFyAuzuL0EnRelSDmmLAsHKbN263vc9Ocy0DrMQTndFoLjrQsGoPDYZDOkyR3Gx2kQTn8Bg9NSyQJi50XU-HsLKnJz9bZ~fikEb41Ym3MIoz3mWpPHy~FMNXk0PnwDHH8J2-UKC39lpV7TI-87s6tFWPmnZCRtc-IgPQcYyOp3hmbdsnzTl7j3yI2ggU7KcByG3I~w~ix4FA__);
  background-size: cover; */
  border-radius: 10px;
}

.vehicle-img .vehicle-img-inner img {
  border-radius: 10px;
  object-fit: cover;
}

.vehicle-detailed-info {
  margin-top: 15px;
}

/* .vehicle-detailed-info{
  overflow-y: scroll;
  overflow-x: hidden;
}

.vehicle-detailed-info::-webkit-scrollbar{
  width: 6px !important;
  background-color: #F5F5F5;
  margin-left: 5px;
  border-radius: 10px;
}

.vehicle-detailed-info::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.vehicle-detailed-info::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #DEE2E6;
} */

.vehicle-detailed-info .title {
  color: #000000;
  /* font-family: "Poppins", sans-serif; */
  font-size: 10px;
  font-weight: 400;
}

.vehicle-detailed-info .title_value {
  color: #838383;
  /* font-family: "Poppins", sans-serif; */
  font-size: 10px;
  font-weight: 400;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  /* display: inline-block; */
}

.vehicle-info-part2 {
  margin-top: 14px;
}

.vehicle-info-part2 .offer-input {
  width: 140px;
  height: 30px;
  border-radius: 12px;
  /* border: 1px solid; */
  border: 1px solid #eceff4;
  letter-spacing: 1px;
  background-color: #ffffff;
  color: #333333;
  /* font-family: "Poppins", sans-serif; */
  font-size: 12px;
  font-weight: 600;
}

.vehicle-info-part2 .offer-input::placeholder {
  color: #d9d9d9;
  font-weight: 400;
}

.vehicle-info-part2 .offer-button {
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #cf33ff;
  /* display: inline-block; */
  border-radius: 10px;
  letter-spacing: 1px;
  width: 70px;
  height: 30px;
  cursor: pointer;
  color: #ffffff;
  /* font-family: "Poppins", sans-serif; */
  font-size: 12px;
  font-weight: 600;
}

.vehicle-info-part2 .offer-button:hover {
  background-color: rgba(207, 51, 255, 0.8);
  /* color: #000000; */
}

.prev_next_btn_section {
  margin-top: 35px;
}

.prev_next_btn_section .prev_btn {
  text-decoration: none;
  font-size: 9px;
  font-weight: 500;
  border: 1px solid #525252;
  background-color: #dee2e6;
  color: #009dff;
  border-radius: 6px;
  padding: 3px 9px 3px 5px;
}

.prev_next_btn_section .next_btn {
  font-size: 9px;
  text-decoration: none;
  font-weight: 500;
  border: 1px solid #009dff;
  background-color: #009dff;
  color: #ffffff;
  border-radius: 6px;
  padding: 3px 5px 3px 9px;
}

.vehicle-info-part2 .form-select {
  cursor: pointer;
  color: #333333;
  font-size: 11px !important;
  font-weight: 500;
  background-color: #ebebeb;
  border-radius: 5px;
  border: 1px solid #009dff;
  padding: 1px 17px;
  width: 30%;
}

.vehicle-info-part2 .form-select:focus {
  box-shadow: none;
}

.box2 {
  height: 340px;

  width: auto;
  /* background: #DEE2E6; */
  background-color: #f7f7f7;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.search-container {
  position: relative;
  width: 100%;
  margin-top: 10px;
}

.search-input {
  width: 100%;
  height: 30px;
  /* padding: 10px 20px; */
  border-radius: 5px;
  /* border: 1px solid #cecece; */
  border: 1px solid #ECEFF4;
  border-radius: 12px;
  box-sizing: border-box;
  font-size: 11px;
  background-color: #ffffff;
  /* padding: 4px 20px; */
  padding:  9px 10px 9px 30px;
  line-height: 13.31px;
  font-weight: 500;
}

.search-button {
  position: absolute;
  color: #96A0B5;
  right: 10px;
  top: 45%;
  background: none;
  border: none;
  /* cursor: pointer; */
}

.search-button img {
  width: 11px;
  height: 11px;
}

.carRow {
  display: grid;
  width: 100%;
  /* grid-template-rows: repeat(5, 60px); */
  gap: 20px;
  background-color: #FFFFFF;
  /* height: 100px; */
  padding: 10px 0px;
  border-radius: 10px;
}

.rows {
  display: grid;
  /* width: 341px; */
  grid-template-columns: 80px 1fr;
  gap: 10px;
}

/* .carDetail {
  display: grid;
  grid-template-rows: repeat(3, 20px);
} */

.headP {
  color: #202020;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  margin: 0;
  letter-spacing: 1px;
  cursor: pointer;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.price {
  background: #0000001C;
  font-size: 8px;
  font-weight: 600;
  letter-spacing: 1px;
  display: inline;
  padding: 4px 2px;
  /* border: 0.1px solid black; */
  border-radius: 2.5px;
  line-height: 12px;
  white-space: nowrap;
}

.lead_score {
  background: #0000001C;
  font-size: 8px;
  font-weight: 600;
  letter-spacing: 1px;
  display: inline;
  padding: 4px 2px;
  /* border: 0.1px solid black; */
  border-radius: 2.5px;
  line-height: 12px;
  white-space: nowrap;
}

.Relatedvehicle_status {
  background: #0000001C;
  font-size: 8px;
  font-weight: 600;
  letter-spacing: 1px;
  display: inline;
  padding: 4px 2px;
  /* border: 0.1px solid black; */
  border-radius: 2.5px;
  line-height: 12px;
  white-space: nowrap;
}

.dropdown-container {
  border: 1px solid;
  display: inline-flex;
  padding: 0px 4px;
  align-items: center;
  border-radius: 3px;
  position: relative;
  cursor: pointer;
  font-family: "Inter";
  font-size: 6px;
  line-height: 12px;
  font-weight: 500;
  white-space: nowrap;
}

.dropdown-container p {
  margin: 0;
}

.makeofferbox {
  font-size: 12px;
  height: 30px;
  width: 144px;
  color: #000;
  border-radius: 12px;
  border: 1px solid #ECEFF4;
  letter-spacing: 1px;
  font-weight: 500;
  line-height: 12px;
  padding: 0px 3px;
  background-color: #FFFFFF;
  &::placeholder {
    color: #D9D9D9;
    font-size: 10px;
  }
}

.offer {
  border: 1px solid #CF33FF;
  background-color: #CF33FF;
  font-size: 12px;
  /* display: inline-block; */
  border-radius: 10px;
  color: #ffffff;
  /* line-height: 12px; */
  font-weight: 600;
  letter-spacing: 1px;
  width: 60px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.offer:hover {
  background-color: rgba(220, 147, 255, 0.6);
}

.values {
  background-color: #ccebfd;
  padding: 1px 3px;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  width: 100%;
}

.values p {
  margin: 0;
  padding: 4px 1px;
  cursor: pointer;
}

.values p:hover {
  background-color: #b4b4b4;
}

.notes_for_each {
  background-color: #efefef;
  height: 300px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.note {
  background-color: #FFFFFF;
  border: 1px solid #ECEFF4;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 8px;
  font-family: "Poppins", sans-serif;
}

.note_input_section {
  /* border: 1px solid #d3d3d3; */
  /* background-color: #FFFFFF; */
  /* border-radius: 8px; */
  max-height: 35%;
  overflow-y: scroll;
  overflow-x: hidden;
}

.note_input_section::-webkit-scrollbar {
  width: 0;
}

.note_input_section input {
  margin-right: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.input_description_sec {
  background-color: #ffffff;
  border: 1px solid #ECEFF4;
  border-radius: 12px;
}

.input_description_sec .input_description {
  height: 35px;
  width: 100%;
  resize: none;
  border: none;
  background-color: transparent;
  color: #737373;
  font-size: 11px;
  font-weight: 700;
}

/* .input_description_sec .input_description::-webkit-scrollbar{
  width: 0;
} */

.note_input_section .add_notes_btn {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.note_input_section .add_notes_btn:hover {
  background-color: #0056b3;
}

.main_nav_detail {
  color: #000000;
  background-color: #ffffff;
  border: 1px solid #eceff4;
  border-radius: 12px;
}

.link_for_back {
  text-decoration: none;
  color: #000000;
  background-color: #ffffff;
  border: 1px solid #eceff4;
  border-radius: 12px;
  /* border-bottom: 1px solid #CECECE;
  border-right: 2px solid #CECECE; */
}

.back_btn_For_note_type {
  /* background-image: url("/public/images/arrow-left-circle-fill.svg");
  background-size: cover;
  background-repeat: no-repeat; */
  height: 16px;
  width: 16px;
  transform: scale(1.3);
}

.back_btn_For_note_type:hover {
  /* background-image: url("/public/images/arrow-left2-circle-fill.svg"); */
}

.output_notes {
  /* max-height: 100%; */
  max-height: 190px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.output_notes::-webkit-scrollbar {
  width: 6px !important;
  background-color: #f5f5f5;
  margin-left: 5px;
  border-radius: 10px;
}

.output_notes::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.output_notes::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #dee2e6;
}

.output_notes .note_title,
.output_notes .note_description {
  word-wrap: break-word;
}

.attatch_sec {
  border-right: 1px solid #cecece;
}

.attatch_sec .attatchment-btn {
  border: none;
  background: url(/src/assets/Vector.svg);
  height: 14px;
  width: 14px;
  background-size: contain;
  background-repeat: no-repeat;
}

.input_title_sec input {
  width: 100%;
  font-size: 10px;
  color: #808080;
  font-weight: 700;
  background-color: #ffffff;
  border: 1px solid #ECEFF4;
  border-radius: 12px;
}

.add_notes_buttons .add_save_btn {
  /* border: 1px solid #838383;
  background-color: #009DFF;
  color: #FFFFFF;
  border-radius: 3px;
  font-size: 7px;
  font-weight: 500; */
  font-size: 9px;
  text-decoration: none;
  font-weight: 500;
  border: 1px solid #CF33FF;
  background-color: #CF33FF;
  color: #ffffff;
  border-radius: 10px;
  padding: 3px 5px 3px 9px;
}

.add_notes_buttons .cancel_clear_btn {
  margin-left: 8px;
  /* border: 1px solid #838383;
  border-radius: 3px;
  color: #6A6A6A;
  font-size: 7px;
  font-weight: 500; */
  font-size: 10px;
  font-weight: 500;
  background-color: #ffffff;
  border: 1px solid #CF33FF;
  color: #CF33FF;
  border-radius: 10px;
  padding: 3px 9px 3px 5px;
}

.note_buttons .edit_btn {
  background: url(/public/img/edit-2.svg);
  background-size: contain;
  background-repeat: no-repeat;
}

.note_buttons .delete_btn {
  background: url(/public/img/trash.svg);
  background-size: contain;
  background-repeat: no-repeat;
}

.edit_delete_btn {
  height: 13px;
  width: 14px;
  border: none;
}

.note:hover {
  border-left: 10px solid #009dff;
}

.note .note_title {
  color: #000000;
  font-size: 12px;
  font-weight: 500;
}

.note .note_date {
  color: #A3A3A3;
  font-size: 12px;
  font-weight: 500;
}

.note .note_description {
  color: #A3A3A3;
  font-size: 10px;
  font-weight: 500;
}

.Adding_title_input:focus {
  outline: none;
}

.input_description_sec .input_description:focus {
  outline: none;
}

.note_files .attached_file,
.attached_files_preview .attached_file {
  color: #009dff;
  font-size: 8px;
  border: 1px solid #cecece;
  border-radius: 10px;
}

.attached_file_remove_btn {
  border: none;
  background-color: transparent;
}

.error-message {
  color: red;
  font-weight: 400;
  font-size: 10px;
}

.add_notes_buttons .add_save_btn .bi::before,
[class^="bi-"]::before,
[class*=" bi-"]::before {
  font-weight: 900 !important;
}

.spinner {
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-top: 3px solid white;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
