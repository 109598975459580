* {
  box-sizing: inherit;
  padding   : 0;
  margin    : 0;
}

html {
  font-family: "Source Sans Pro", sans-serif;
  box-sizing : border-box;
}

.App {
  // height: 100vh;
  position       : relative;
  display        : flex;
  align-items    : center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.cst-hover {
  &:hover {
    cursor: pointer;
    color : #338CF0 !important;
  }
}

.overlay-notifcation {
  // width   : 65%;
  // height  : 100%;
  right   : 0px;
  position: absolute;
  background-color: #00000029;
}

.img-button {
  font-size  : 11px;
  font-weight: 500;
  color      : #009DFF;
  font-style : italic;
  border     : none;
  background : transparent;

}

.accounts-table {
  th, tr {
    font-size: 11px;
  }
}

.loader {
  width              : 48px;
  height             : 48px;
  border             : 5px solid #000;
  border-bottom-color: transparent;
  border-radius      : 50%;
  display            : inline-block;
  box-sizing         : border-box;
  animation          : rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
.icon-hover {

  &:hover {
    .icon-hover-list {
      display: block;
    }
  }
  .icon-hover-list {
    position: relative;
    display: none;

    &::after {
      content: "";
      position: absolute;
      bottom: calc(100% - 2px);
      right: 20px;
      width       : 0px;
      height      : 0px;
      border-style: solid;
      border-width: 0 5.5px 7px 5.5px;
      border-color: transparent transparent #FFF transparent;
      transform   : rotate(0deg);
    }

    .icon-hover-items {
      position        : absolute;
      background-color: white;
      width           : 187px;
      // padding         : 10px 7px 5px 7px;
      padding: 14px;
      right           : 10px;
      border-radius   : 4px;
      box-shadow: 0px 4px 20.5px 0px #A2A2A240;

      
      .icon-hover-item {
        margin-bottom: 5px;
        display: flex;
        gap: 15px;
        font-size  : 13px;
        font-weight: 400;
        font-style: normal;
        font-family: "Inter", sans-serif;
        white-space: nowrap;
        color : black !important;

        &:hover {
          color: #009DFF !important
        }
      }
    }
  }
}

.roate-360-hover {
  transition      : all cubic-bezier(0.165, 0.84, 0.44, 1) 1s;
  // transition: all ease-out 1s;
  transform       : rotateZ(0deg);
  transform-origin: center center;

  &:hover {
    transform: rotateZ(359deg);
  }
}

.spinner-border {
  --bs-spinner-width : 1rem;
  --bs-spinner-height: 1rem;
}

.receving-call-container2.call-schedule {
  font-family   : "Inter", sans-serif !important;
  pointer-events: unset;
  
  
  .call-schedule-form {
    overflow        : hidden;
    border-radius   : 15px;
    padding         : 15px 15px 0px 15px;
    background-color: white;
    color           : black;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;

    h6 {
      font-weight: 700;
      font-size: 16px;
    }

    .note-footer {
      overflow    : hidden;
      width       : calc(100% + 30px);
      border-top: 1px solid #EBEBEB;
      // box-shadow  : 0 0 4px 0 rgba(0, 0, 0, .3);
      position    : relative;
      margin-left : -15px;
      margin-right: -15px;
      display     : flex;
      padding     : 15px 15px;

      justify-content: end;



      .note-cancel {
        cursor       : pointer;
        font-weight  : 600;
        border-radius: 10px;
        font-size    : 12px;
        border       : 1px solid #CF33FF;
        padding      : 12px 40px;
        color        : #CF33FF;
        // box-shadow   : inset 0 -1px 0 #D0D4DF;
        // background   : transparent linear-gradient(180deg, #FCFDFF 0%, #EAEEF5 100%);
        outline      : 0;
        margin-right : 10px;
      }

      .note-action {
        cursor         : pointer;
        font-weight    : 600;
        border-radius  : 10px;
        font-size      : 12px;
        border         : 1px solid #CF33FF;
        padding        : 12px 40px;
        color          : #fff;
        // box-shadow     : inset 0 -1px 0 #0061CA;
        background     : #CF33FF;
        outline        : 0;
        display        : flex;
        justify-content: center;
        align-items    : center;


      }

      // z-index: 10;
    }

    .note-container {
      display      : flex;
      margin-bottom: 10px;

      .note-label {
        flex-basis    : 150px;
        // width      : 140px;
        position      : relative;
        top           : 1px;
        padding       : 8px 10px 5px 0 !important;
        color         : #000000;
        display       : inline-block;
        width         : auto;
        text-align    : left;
        line-height   : 19px;
        font-size     : 16px;
        font-weight: 500;
      }

      .note-error {
        width    : 100%;
        font-size: 10px;
        color    : red;
      }

      .note-action {
        width: 100%;

        .input-wrap {
          width         : 100%;
          // border-color  : #787D83 !important;
          border: 1px solid #F0F4FA !important;
          box-shadow    : none;
          transition    : box-shadow .1s ease-in-out;
          color         : #313949;
          vertical-align: bottom;
          display       : inline-block;
          position      : relative;
          padding       : 2px 10px !important;
          top           : 1px;
          transition    : initial;
          // border        : 1px solid #C0C6CC;
          border-radius : 5px;
          background-color: #F7F7F7;
        }

        .icon {
          position: absolute;
          right   : 5px;
          top     : 1px;
        }

        .badgex {
          position: absolute;
          right   : 23px;
          top     : 1px;
        }

        input {
          width           : 100%;
          background-color: transparent;
          padding         : 0;
          border          : none !important;
          box-shadow      : none !important;
          margin-bottom   : 0 !important;
          max-width       : 100%;
          font-size       : 13px;
          color: #000;

          &:focus{ 
            outline     : none !important;
            box-shadow  : none;
            border-color: none;
          }
        }
      }
    }


  }
}

.new-action-btn {
  cursor: pointer;
  background-color: #F7F7F7;
  font-size: 16px;
  color: black;
  padding: 5px 12px;
  border-radius: 7px;
  transition: all ease-in 0.1s;
  border: 1.5px solid #d0d0d000;

  &:hover {
    background-color: #efeeee;
    border: 1.5px solid #d0d0d0;
  }
}


.side_menu{
  position: relative;
  // width: 87px;
  background-color: #CF33FF;
  border-radius: 20px 0px 0px 20px;
  // position: relative;
  // z-index: 1;

  &::after {
    content: "";
    position: absolute;
    background-color: #CF33FF;
    width: 20px;
    height: 100%;
    left: 100%;
  }
}

.side_menu .side_txt{
  font-size: 10px;
  font-weight: 500;
  color: #FFFFFF;
}

.side_main_every {
  position: relative;
  cursor: pointer;
}

.side_active_sec {
  height: 55px;
  width: 6px;
  border-radius: 0 20px 20px 0;
  background-color: #FFFFFF;
  position: absolute;
  top: -10px;
  display: none; /* Hide by default */
}

.active .side_active_sec {
  display: block; /* Show active section */
}
