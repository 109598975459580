@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

.link_note{
  color: transparent;
  border-bottom: 1px solid #EBEBEB;
  padding-bottom: 8px;
}

.link_note:last-child {
  border-bottom: none;
}

.Dealer_name {
  /* margin     : 0px 45px; */
overflow   : hidden;
white-space: nowrap;
text-overflow: ellipsis;
}

.container1 {
  /* width: 100%; */
  /* height: 100vh; */
  /* height: 465px; */
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Inter", sans-serif;
}

.car-container {
  position: relative;
    width: 100%;
    /* height: 465px; */
    height: 100%;
    /* background: #DEE2E6; */
    background-color: #F7F7F7;
    /* border-radius: 10px; */
    padding: 0px 20px;
  }
  
  .car_and_name {
    margin-top: 12px;
    /* height: 166px; */
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
    gap: 10px;
    padding: 8px 10px;
    background-color: #FFFFFF;
    border-radius: 10px;
  }
  
  .car_img {
    /* display: grid; */
    height: fit-content;
    border: 1px solid #AEAEAE;
    border-radius: 10px;
  }
  
  .car_name {
    /* display: grid; */
    /* grid-template-columns: 145px; */
    font-weight: 500;
    font-size: 14px;
    color: black;
    letter-spacing: 1px;
    overflow: hidden;
}

.car_name .car_name_text {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limits the text to two lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal; /* Allows wrapping of text */
}


.contact_btn{
  height: 33px;
  width: 33px;
}
  
  .Dealer_slider {
    /* width: calc(100% - 40px); */
    /* height: 37px; */
    /* background-color: #E3E3E3; */
    /* border: 1px solid #AEAEAE; */
    /* border-radius: 15px; */
    /* margin: auto; */
    margin: 15px 0px;
    font-weight: 700;
    font-size: 15px;
    /* line-height: 24.2px; */
  }
  
  .sbtn {
    /* width: 16px;
    height: 16px; */
    /* color: #333333; */
    cursor: pointer;
  }
  
  .contacts_container{
    padding: 11px ;
    background-color: #FFFFFF;
    border-radius: 10px;
    margin-bottom: 16px;
  }

  .contacts {
    height: 250px;
    overflow-y: scroll;
    overflow-x: hidden;
    display: grid;
    /* grid-template-rows: repeat(4, 40px); */
    /* margin: 10px 20px 20px 20px; */
    padding-bottom: 50px;
    gap: 15px;
  }

  .contacts::-webkit-scrollbar{
    width: 6px !important;
  background-color: #F5F5F5;
  margin-left: 5px;
  border-radius: 10px;
  }


  .contacts::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.contacts::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #DEE2E6;
}
  
  .contact_row {
    
    display: grid;
    grid-template-columns: 36px 1fr 36px 36px 36px;
    gap: 10px;
    align-items: center;
    /* cursor: pointer; */
  }
  
  .name_num {
    display: grid;
    grid-template-rows: repeat(2, 20px);
    height: 40px;
    gap: 3px;
    margin-right: 15px;
    cursor: pointer;

    &:hover {
      .name, .number{
        color: #338CF0;
      }
    }
  }
  
  .name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 500;
    font-size: 15px;
    /* line-height: 21.78px; */
    color: black;
    letter-spacing: 1px;
  }
  
  .number {
    font-weight: 400;
    font-size: 12px;
    line-height: 18.15px;
    color: #00000080;
    letter-spacing: 1px;
  }
  
  .inner_car_img {
    width: 140px;
    height: 80px;
    object-fit: cover;
    border-radius: 10px;
  }

  .header_car_and_bids_txt{
    font-size: 16px;
    font-weight: 700;
  }

  .header_car_and_bids_navs_btn{
    cursor: pointer;
    height: 20px;
  }