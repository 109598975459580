.number-item {
  font-family: "Inter", sans-serif;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  border: solid 0px #ececec;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all ease-in-out 0.3s;
  margin: auto;
  // box-shadow: 0 0 2px rgba($color: #000000, $alpha: 0.25);
  background-color: #F3F3F4;

  &:hover {
    background-color: #ecf8f0;
  }

  .number {
    font-size: 15px;
    font-weight: 600;
    line-height: 21px;
    color: #322;
  }

  .letters {
    font-size: 6px;
    line-height: 10px;
    text-transform: uppercase;
    letter-spacing: 0.8px;
    color: #555;
  }
}
