.connection-status {
  .status {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    cursor: pointer;

    &.connected {
      background-color: #cf33ff;
    }

    &.connecting {
      animation: connectingMove 0.7s alternate infinite;
      // background-color: yellow;
    }

    &.disconnected {
      background-color: red;
    }
  }

}

@keyframes connectingMove {
  from {
    background-color: yellow;
  }

  to {
    background-color: rgb(232, 232, 232);
  }
}

.call-dialer-info {
  margin-top: 10px;

  .caller-status {
    display: flex;
    align-items: center;

    a {
      text-decoration: none;
      // margin-top: 5px;

      h6 {
        margin-bottom: 0px;
      }
    }

  }
}

.call-dialer-type {
  font-size: 12px;
  background-color: #00008b;
  padding: 2px 10px;
  margin-right: 5px;
  margin-left: 5px;
  border-radius: 5px;
  color: white;
  width: fit-content;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  transform: scale(0.35) translate(-20px, -25px);
  // transform: ;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #000000;
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.phone-number {
  font-size: 12px;
  border: 1px solid black;
  padding: 0px 4px;
  border-radius: 4px;
  // margin-left: 4px;
  transform: translateY(2px);
}

.add-note-wrap {
  z-index: 103;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(208, 208, 208, 0.61);
  backdrop-filter: blur(5px);
}

.add-note-container {
  border: 1px solid black;
  margin: 5px 20px;
  position: absolute;
  z-index: 99;
  background-color: rgb(255, 254, 254);
  width: -webkit-fill-available;
  height: min(200px, 100%);
  top: 50%;
  transform: translateY(-50%);
  // bottom: 10px;
  padding: 10px 20px;
  border-radius: 7px;

  .add-note-button {
    width: 100%;
    background-color: greenyellow;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    padding: 5px 10px;
    border-radius: 7px;
    cursor: pointer;
  }
}
.receving-call-container {
  padding: 5px 5px !important;
}

.receving-call-container, .receving-call-container2 {
  position: absolute;
  z-index: 103;
  width: min(350px, 100%);
  height: min(550px, 100%);
  padding: 10px 20px;
  pointer-events: none;

  .receving-call {
    pointer-events: visible;
    border-radius: 12px;
    padding: 10px 10px;
    margin-bottom: 10px;
    position: relative;
    background-color: #6a6a6ae8;
    color: white;
    display: flex;
    justify-content: space-between;

    .name {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      justify-content: center;
      color: white;
    }

    .answer {
      flex-basis: 50px;
      display: flex;
      gap: 5px;
      align-items: center;

      .yes {

        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: 50%;
        background-color: #5fdd5a;
      }

      .no {
        transform: rotate(135deg);
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: 50%;
        background-color: red;
      }
    }
  }
}

.receving-call-container2 {
 width: 100% 
}

.mobile-container {
  border-radius: 20px 0px 0px 20px;
  // width: min(350px, 100%);
  // height: min(550px, 100%);
  // height: 465px;
  height: 100%;
  // width: 300px;
  // height: 542px;
  // background-color: #efefef;
  background-color: #FFFFFF;
  // box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  // box-shadow: rgba(99, 99, 99, 0.4) 0px 2px 8px 0px;
  // border-radius: 20px;
  padding: 10px;
  position: relative;

  .input-wrapper {
    position: relative;
    display: flex;

    .phone-number-input {
      text-align: center;
      font-family: "Inter", sans-serif;
      border: none;
      outline: none;
      height: 30px;
      font-size: 16px;
      width: 100%;
      // background-color: #efefef;
      border-radius: 15px;
      border: 1.5px solid #ECEFF4;
  // box-shadow: rgba(99, 99, 99, 0.4) 0px 2px 8px 0px;;
      // margin: 50px 0px 12px 0;
      margin-bottom: 20px;

      &::placeholder {
        font-size: 16px;
      }
    }

    .delete-btn {
      cursor: pointer;
      // margin: 20px 0;
      display: flex;
      align-items: center;
      position: absolute;
      right: 10px;
      top: 6px;

      .delete-icon {
        font-size: 28px;
        color: darkred;
      }
    }
  }

  .call-btn {
    width: 54px;
    height: 54px;
    border-radius: 50%;
    border: none;
    background-color: #5fdd5a;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    box-shadow: 0 0 6px 8px rgba($color: #000000, $alpha: 0.05);
    transition: all ease-in-out 0.2s;

    &:hover {
      transform: scale3d(1.1, 1.1, 1);
    }

    .call-icon {
      color: white;
      font-size: 26px;
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;



    .history-btn {
      display: flex;
      align-items: center;
      font-size: 22px;
      color: #ffb40b;
      border-radius: 50%;
      transition: all ease-in-out 0.3s;
      cursor: pointer;

      &:hover {
        background-color: #e8e8fc;
        box-shadow: 0 0 4px rgba($color: #000000, $alpha: 0.25);
      }
    }
  }
}

.logo {
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 1px;
  color: #00008b;
}

#session-present {
  display: none;
}

.overlay-fix {
  position: absolute;
  z-index: 100;
  width: min(350px, 100%);
  height: min(550px, 100%);
  padding: 10px 20px;
  background-color: #6666664b;
  // border-radius: 20px;

  display: flex;
  justify-content: center;
  align-items: center;

  .overlay-container {

    background-color: white;
    border-radius: 10px;
    padding: 20px 10px;
    text-align: center;

    p {
      margin-bottom: 10px;
    }

  }

  .reload-btn svg {
    cursor: pointer;
    transform: rotatez(0deg);
    transition: all 0.3s ease-in-out;

    &:hover {
      transform: rotatez(360deg);
    }
  }
}

.logout-btn {
  // z-index: 101;
  z-index: 1;
  background-color: white;
  border-radius: 50%;
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.unknown-action {
  display: flex;
  margin-top: 30px;
}

.unknown-action-btn {
  font-size: 12px;
  cursor: pointer;
  background-color: #00008b;
  padding: 2px 10px;
  margin-right: 5px;
  margin-left: 5px;
  border-radius: 5px;
  color: white;
  width: -moz-fit-content;
  width: fit-content;
}