.call-container {
  width: 100%;
  // height: 465px;
  height: 100%;
  background-color: #fff;
  // box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  // box-shadow: rgba(99, 99, 99, 0.4) 0px 2px 8px 0px;
  // border-radius: 20px;
  padding: 10px 20px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px 0px 0px 20px;

  

  .call-top {
    .avatar {
      border-radius: 50%;
      background-color: #efefef;;
      width: 0px;
      height: 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;

      .person-icon {
        font-size: 48px;
        color: #888;
      }
    }

    .phone-number {
      font-size: 22px;
      color: #555;
      letter-spacing: 1px;
      font-weight: 600;
      margin: 8px 0 2px;
      border: unset;
    }

    .status {
      color: #999;
      letter-spacing: 1px;
      display: block;
      text-align: center;
    }
  }

  .keypad {
    width: 90%;
    padding: 10px;
    border-radius: 7px;
    position: absolute;
    top: 50%;
    left: 50%;
    background-color: white;
    transform: translate(-50%, -50%);

    .number-keyboard {
      margin-top: 8px;
      margin-bottom: 8px;

      .item {
        margin-bottom: 10px;

        .number-item {
          width: 46px;
          height: 46px;
        }
      }
    }

    .phone-number {
      font-size: 22px;
      font-weight: 600;
      letter-spacing: 2px;
      min-height: 28px;
    }

    .close-icon {
      position: absolute;
      top: 4px;
      right: 10px;
      font-size: 24px;
      cursor: pointer;
      color: darkred;
    }
  }

  .call-bottom {
    .actions {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;

      

      .actions-item {
        margin: 0 5px;
        cursor: pointer;
        transition: all ease-in-out 0.3s;
        border-radius: 50%;
        width: 36px;
        height: 36px;
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: center;

        img {
          min-width: 24px;
          min-height: 24px;
        }

        .action-text {
          font-size: 14px;
        }

        .actions-speaker {}

        &:hover {
          // background-color: rgba(0, 0, 0, 0.05);
        }

        .actions-icon {
          font-size: 24px;
        }

        &.active {
          // background-color: rgba(0, 0, 0, 0.05);
          // box-shadow: 0 0 4px 2px rgba($color: #000000, $alpha: 0.25);
        }
      }
    }

    .cancel {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      border: none;
      background-color: #ff5353;
      color: #fbfbff;
      font-size: 32px;
      justify-self: flex-end;
      margin: auto;
      cursor: pointer;
      box-shadow: 0 0 6px 8px rgba($color: #000000, $alpha: 0.05);
      transition: all ease-in-out 0.2s;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        transform: scale3d(1.05, 1.05, 1);
      }
    }
  }
}